import { Link, useLoaderData } from "react-router-dom";
import { IOwnerSummary } from "../../interface";
import {
  Col,
  Row,
  Table,
  type TableColumnsType,
  Typography,
  Input,
  Button,
  Grid,
} from "antd";
import Highlighter from "react-highlight-words";

import { useEffect, useState } from "react";
const { Title } = Typography;
const { Search } = Input;
const { useBreakpoint } = Grid;


const Owners: React.FC = () => {
  const data = useLoaderData() as IOwnerSummary[];
  const screens = useBreakpoint();
  console.log(data);

  const [searchValue, setSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState<IOwnerSummary[]>(data);

  useEffect(() => {
    if (data.length > 0) {
      if (searchValue) {
        const temp = data.filter((owner) =>
          owner.email.includes(searchValue)
        );
        setFilteredData(temp);
      } else {
        setFilteredData(data);
      }
    }
  }, [data, searchValue]);

  const columns: TableColumnsType<IOwnerSummary> = [
    {
      title: "Email",
      key: "id",
      dataIndex: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
      render: (text) => (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchValue]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ),
    },
    {
      title: "",
      key: "actions",
      width: 100,
      render: (_, record) => <Link to={record.id}>Manage</Link>,
    },
  ];

  return (
    <>
      <Row>
        <Col span={24}>
          <div
            style={{
              background: "white",
              borderRadius: 4,
              boxShadow: "0 0 8px 0 #cbcbcb",
              padding: 20,
            }}
          >
            <Row gutter={[24, 24]}>
              <Col md={8} sm={8} xs={24}>
                <Title level={4} style={{ marginBottom: 0 }}>
                  All System Operators
                </Title>
              </Col>
              <Col md={16} sm={16} xs={24}>
                <div style={{ display: "flex" }}>
                  <Search
                    placeholder="Search System Operator"
                    allowClear
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                </div>
              </Col>
              <Col span={24}>
                <Table
                  columns={columns}
                  dataSource={filteredData}
                  rowKey="id"
                  scroll={{
                    x: "max-content",
                  }}
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default Owners;
